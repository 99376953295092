
import { Options, Vue } from 'vue-class-component';
import { selfUrl } from '@/config';
import store from '@/store';
import { StorageServices } from '@/services/StorageServices';

@Options({})
export default class Layout extends Vue {
    
    viewKey: string = Date.now().toString();
    activeTab: string = "";
    currentUrl: string = "";

    created() {        
        // if(store.state.query) {
        //     this.$nextTick( () => 
        //     {   
        //         var tempQuery = store.state.query.replace(selfUrl + "#", "");
        //         tempQuery = tempQuery.replace(selfUrl, "/");
        //         this.$router.push(tempQuery);
        //     })
        // }

    }

    get routeKey() {
        if(this.$route.meta.clearCache)
            return Date.now().toString();
    }

    openApp() {
        var url = store.state.query;
        store.state.query = "";
        window.open("eu.uose.app://" + url, "_blank");
        this.$router.replace(this.$route.fullPath.replace("?open_app_uose=true", ""));
    }

    noApp() {
        store.state.query = "";
        this.$router.replace(this.$route.fullPath.replace("?open_app_uose=true", ""));
    }

}

